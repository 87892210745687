import React, { useState } from 'react'
import SideBar from '../components/Sidebar'
import NavBar from '../components/NavBar'
import Hero from '../components/Hero'
import Info from '../components/Info'
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/Info/data'
import Instructions from '../components/Instructions'

const Home = () => {
  const [isOpen, setIsOpen ] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
    <SideBar $isopen={isOpen} toggle={toggle}/>
    <NavBar toggle={toggle}/>
    <Hero/>
    {/* <Info {...homeObjOne}/>
    <Info {...homeObjTwo}/>
    <Instructions/>
    <Info {...homeObjThree}/> */}
    </>
  )
}

export default Home