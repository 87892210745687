import { Link } from "react-router-dom";
import styled from "styled-components";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({$primary}) => ($primary ? '#F6F930' : '#383F51')};
  white-space: nowrap;
  padding: ${({$big}) => ($big ? '14px 48px' : '12px 30px')};
  color: ${({$dark}) => ($dark ? '#101e20' : '#ededed')};
  font-size: ${({$fontbig}) => ($fontbig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({$primary}) => ($primary ? '#fff': '#01BF71')};
  }
`;
