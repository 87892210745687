import React from 'react'
import { 
    SideBarContainer, 
    Icon, 
    CloseIcon,
    SideBarLink,
    SideBarWrapper,
    SideBarMenu,
    SideBarRoute,
    SideBtnWrap
} from './sidebarElements'

export default function SideBar({$isopen, toggle}) {

  return (
    <>
    <SideBarContainer $isopen={$isopen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SideBarWrapper>
            <SideBarMenu>
                <SideBarLink to='about' onClick={toggle}>
                    About
                </SideBarLink>

                <SideBarLink to='discover' onClick={toggle}>
                    Discover
                </SideBarLink>

                <SideBarLink to='instructions' onClick={toggle}>
                Instructions
                </SideBarLink>

                <SideBarLink to='signup' onClick={toggle}>
                    Sign Up
                </SideBarLink>
            </SideBarMenu>
            <SideBtnWrap>
                <SideBarRoute to='/form'>Get Started</SideBarRoute>
            </SideBtnWrap>
        </SideBarWrapper>
    </SideBarContainer>
    </>
  )
}
