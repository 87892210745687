import React from "react";
import { FaBars } from 'react-icons/fa'
import { 
    Nav, 
    NavBarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
  } from "./NavElements";


export default function NavBar({toggle}) {
  return (
    <>
      <Nav>
        <NavBarContainer>
            <NavLogo to='/'
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            >
                protechchat
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars onClick={toggle}/>
            </MobileIcon>
            <NavMenu>
                <NavItem>
                    <NavLinks to='about'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}>About</NavLinks>
                </NavItem>

                <NavItem>
                    <NavLinks to='discover'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    >Discover</NavLinks>
                </NavItem>

                <NavItem>
                    <NavLinks to='instructions'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    >Instructions</NavLinks>
                </NavItem>

                <NavItem>
                    <NavLinks 
                    to='signup'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    >Start Now</NavLinks>
                </NavItem>
            </NavMenu>
            <NavBtn>
            <NavBtnLink to='/form'>Get Started</NavBtnLink> 
            </NavBtn>
        </NavBarContainer>
      </Nav>
    </>
  );
}

// 1:39:25 / 3:44:07
