import React, { useState } from "react";
import axios from "axios";

import {
  HeroBg,
  HeroContainer,
  VideoBg,
  HeroH1,
  HeroP,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  FormInput,
  FormInputContainer,
  SendButton
} from "./HeroElements";
import { Button } from "../ButtonElement";


export default function Hero() {
  const videoURL = "https://golfgolfbackgroundvideobucket.s3.amazonaws.com/Video.mp4";
  const [email, setEmail] = useState("");
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
          'https://4z04pfy5re.execute-api.us-east-1.amazonaws.com/glfglfdev/add-member',
          {
              email: email,
          }
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
    setEmail('');
};

  



  return (
    <>
      <HeroContainer id="/">
        <HeroBg>
          
        </HeroBg>
        <HeroContent>
          <HeroH1>Pro help without the pricetag</HeroH1>
          <HeroP>
          Avoid ridiculous fees for simple HVAC fixes. Talk to one of our remote techs today to get a diagnosis at a fraction of the cost.
          </HeroP>
          <HeroBtnWrapper>
          <Button to='/form' 
                smooth="true"
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                onMouseEnter={onHover} 
                onMouseLeave={onHover}
                $primary = 'true'
                $dark='true'
                 >
                   Get Started {hover ? <ArrowForward/> : <ArrowRight/>}
                 </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
}


// {/*  */}