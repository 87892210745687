const ML = "https://golfgolfbackgroundvideobucket.s3.amazonaws.com/Golf.svg"

export const homeObjOne = {
    id: 'about',
    $lightbg: false,
    $lighttext: true,
    lightTextDesc: true,
    topLine: 'Ai Solutions',
    headline: 'Get a step ahead with roemLAB',
    description: 'With roemLAB, breathe new life into your career hunt! We blend cutting-edge AI technology with traditional data management and dynamic visualization techniques, creating an unparalleled platform that turbocharges your personal professional journey.',
    buttonLabel: 'Get Started',
    $imgstart: false,
    alt: 'ML',
    $img: ML,
    $dark: true,
    $primary: true,
    $darktext: false,
    $path: '/signUp'
}

export const homeObjTwo = {
    id: 'discover',
    $lightbg: true,
    $lighttext: false,
    lightTextDesc: false,
    topLine: 'A new approach ',
    headline: 'Always be looking for the next adventure',
    description: 'In todays job market, the key to advancement often means moving on. Job hopping, widely accepted as a means to climb positions and boost salaries, is where we come in. Were dedicated to keeping you up to date on your potential opportunities.',
    buttonLabel: 'Get Started',
    $imgstart: true,
    alt: 'Image Choice',
    $img: null,
    $dark: false,
    $primary: false,
    $darktext: true,
    $path: '/signUp'
}

export const homeObjThree = {
    id: 'signup',
    $lightbg: true,
    $lighttext: false,
    lightTextDesc: false,
    topLine: 'Whats to consider',
    headline: 'An exciting new career awaits',
    description: 'Whether youre an industry newcomer or a seasoned veteran seeking advancement, we can streamline your search for that pivotal new position, propelling your career to greater heights.',
    buttonLabel: 'Start Now',
    $imgstart: false,
    alt: 'Golf',
    $img: null,
    $dark: false,
    $primary: false,
    $darktext: true,
    $path: '/signUp'
}